export function formatPrice(value: number) {
    return `${(value / 100).toFixed(2)} PLN`;
}

export function formatDate(value: Date | number | string | undefined | null) {
    const date = parseDate(value);
    if (date === null) return "";
    const d = dateToValues(date);
    return `${d.DD}/${d.MM}/${d.YYYY}`;
}

export function formatDateTime(value: Date | number | string | undefined | null) {
    const date = parseDate(value);
    if (date === null) return "";
    const d = dateToValues(date);

    return `${d.DD}/${d.MM}/${d.YYYY} ${d.hh}:${d.mm} `;
}

export function parseDateValue(value: Date | number | string | undefined | null): Date | null {
    if (typeof value === "number" || typeof value === "number") {
        return new Date(value);
    }

    if (typeof value === "object" && value instanceof Date) {
        return value;
    }

    return null;
}

export function isDateValid(value: Date) {
    try {
        if (Number.isNaN(value.valueOf())) return false;
        return true;
    } catch (e) {
        return false;
    }
}

export function parseDate(value: Date | number | string | undefined | null): Date | null {
    const parsedDate = parseDateValue(value);
    if (parsedDate === null) return null;

    if (!isDateValid(parsedDate)) return null;

    return parsedDate;
}

function fillZero(value: string | number) {
    if (typeof value === "number") {
        value = value.toString();
    }

    if (value.length === 2) return value;

    return "0" + value;
}

function dateToValues(date: Date) {
    return {
        hh: fillZero(date.getHours()),
        mm: fillZero(date.getMinutes()),
        DD: fillZero(date.getDate()),
        MM: fillZero(date.getMonth() + 1),
        YYYY: date.getFullYear(),
    };
}

export function formatDecimal(value: number, precision?: number) {
    if (!precision) precision = 2;

    return value.toFixed(precision);
}

export function formatTimer(value: number) {
    if (value < 0) return `00h 00m 00s`;

    const second = 1000;
    const minute = 60 * second;
    const hour = 60 * minute;

    const hours = Math.floor(value / hour);
    value = value - hours * hour;
    const minutes = Math.floor(value / minute);
    value = value - minutes * minute;
    const seconds = Math.floor(value / second);

    return `${fillZero(hours)}h ${fillZero(minutes)}m ${fillZero(seconds)}s`;
}

export function formatHour(value: number) {
    return Math.ceil(value / (60 * 60 * 1000));
}
