import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Endpoint } from "@3destatepl/crm-cache-endpoint";

import { useContextAppProps, ContextApp, useContextApp } from "./context/context";
import { useDatasource } from "./hooks/useDatasource";
import Auth from "./pages/Auth";
import SheetList from "./pages/SheetList";

const App: React.FC = () => {
    const contextApp = useContextAppProps();

    return (
        <BrowserRouter>
            <ContextApp.Provider value={contextApp}>
                <CheckAuthToken />
            </ContextApp.Provider>
        </BrowserRouter>
    );
};

const CheckAuthToken: React.FC = () => {
    const {
        authToken: { authToken },
    } = useContextApp();

    const dsIsAuthOk = useDatasource(Endpoint.Auth.CheckTokenValid);

    useEffect(() => {
        if (!authToken) return;
        dsIsAuthOk.load({ data: { token: authToken ?? "" } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken]);

    if (!authToken || (dsIsAuthOk.state.state === "completed" && !dsIsAuthOk.state.response.isAuth)) {
        return <Auth />;
    }
    if (dsIsAuthOk.state.state === "idle" || dsIsAuthOk.state.state === "pending") {
        return <div className="page__container">Autoryzacja...</div>;
    }
    if (dsIsAuthOk.state.state === "completed" && dsIsAuthOk.state.response.isAuth) {
        return <SheetList />;
    }
    return (
        <div className="page__container">
            <b>Błąd</b>
        </div>
    );
};

const Routes: React.FC = () => {
    return (
        <div className="page__container">
            <Switch></Switch>
        </div>
    );
};

export default App;
