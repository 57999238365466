import React, { useRef } from "react";
import { useContextApp } from "../context/context";

const Auth: React.FC = () => {
    const {
        authToken: { setAuthToken, authToken },
    } = useContextApp();
    const inputValue = useRef(authToken);

    return (
        <div className="">
            <input
                type="text"
                onChange={e => {
                    inputValue.current = e.target.value;
                }}
            />
            <button onClick={() => submit()}>Zapisz</button>
        </div>
    );

    function submit() {
        if (!inputValue.current) return;
        setAuthToken(inputValue.current);
    }
};

export default Auth;
