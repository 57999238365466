import { createContext, useContext } from "react";
import { useAuthToken } from "../hooks/useAuthToken";

type ContextAppProps = ReturnType<typeof useContextAppProps>;

export function useContextAppProps() {
    const authToken = useAuthToken();
    return { authToken };
}

export const ContextApp = createContext<ContextAppProps>({} as ContextAppProps);

export function useContextApp() {
    return useContext(ContextApp);
}
