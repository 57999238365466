import React, { useEffect, useState } from "react";
import { Endpoint } from "@3destatepl/crm-cache-endpoint";
import { Endpoint as WebExampleEndpoint } from "@3destatepl/web-example-endpoint";
import { CacheStateRange, CacheStateSpreadsheet } from "@3destatepl/crm-cache-types";

import { useDatasource, useDatasourceWebGenerate } from "../hooks/useDatasource";

import "./SheetList.scss";
import { formatDateTime } from "../utils/format";

const SheetList: React.FC = () => {
    const dsState = useDatasource(Endpoint.Cache.GetState);

    useEffect(() => {
        dsState.load({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    switch (dsState.state.state) {
        case "idle":
        case "pending":
            return <div>Ładowanie statusu...</div>;

        case "completed":
            return (
                <div className="center">
                    <RefreshGenerateWeb />
                    <UpdatePushAPIGroupsConfig />
                    <UpdateEmailsConfig />
                    <div className="sheet-list">
                        {dsState.state.response.state.spreadsheets.map(s => (
                            <Spreadsheet spreadsheet={s} key={s.id} onDeleted={() => {}} />
                        ))}
                    </div>
                </div>
            );

        default:
            return <div className="">Błąd ładowania statusu</div>;
    }
};

export default SheetList;

const RefreshGenerateWeb: React.FC = () => {
    const dsGenerate = useDatasourceWebGenerate(WebExampleEndpoint.ExampleGenerate.Refresh);

    if (dsGenerate.state.state === "idle" || dsGenerate.state.state === "completed") {
        return (
            <div className="generate-refresh">
                <button onClick={() => dsGenerate.load({})}>Wygeneruj nowe strony defaultowych implementacji</button>
            </div>
        );
    }

    if (dsGenerate.state.state === "pending") {
        return <div className="generate-refresh">Generowanie...</div>;
    }

    return <div className="generate-refresh">Error</div>;
};

const UpdatePushAPIGroupsConfig: React.FC = () => {
    const dsGenerate = useDatasource(Endpoint.External.PostRefreshPushAPIGroupsConfig);

    if (dsGenerate.state.state === "idle" || dsGenerate.state.state === "completed") {
        return (
            <div className="generate-refresh">
                <button onClick={() => dsGenerate.load({})}>Odśwież konfigurację grup w PUSH API</button>
            </div>
        );
    }

    if (dsGenerate.state.state === "pending") {
        return <div className="generate-refresh">Odświeżanie...</div>;
    }

    return <div className="generate-refresh">Error</div>;
};

const UpdateEmailsConfig: React.FC = () => {
    const dsGenerate = useDatasource(Endpoint.External.PostRefreshEmailConfig);

    if (dsGenerate.state.state === "idle" || dsGenerate.state.state === "completed") {
        return (
            <div className="generate-refresh">
                <button onClick={() => dsGenerate.load({})}>Odśwież konfigurację emails</button>
            </div>
        );
    }

    if (dsGenerate.state.state === "pending") {
        return <div className="generate-refresh">Odświeżanie...</div>;
    }

    return <div className="generate-refresh">Error</div>;
};

const Spreadsheet: React.FC<{ spreadsheet: CacheStateSpreadsheet; onDeleted: () => void }> = ({
    spreadsheet,
    onDeleted,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dsDeleteSheet = useDatasource(Endpoint.Cache.DeleteSheet);

    const [confirmDelete, setConfirmDelete] = useState(false);

    if (dsDeleteSheet.state.state === "completed") {
        return null;
    }

    return (
        <div className="spreadsheet">
            <div className="spreadsheet__wrapper">
                <div className="spreadsheet__name" onClick={() => setIsOpen(p => !p)}>
                    {spreadsheet.title ? (
                        <>
                            {spreadsheet.title} <span className="spreadsheet__name__small">{spreadsheet.id}</span>
                        </>
                    ) : (
                        spreadsheet.id
                    )}
                </div>
                <div className="spreadsheet__button-group">{deleteButton()}</div>
            </div>
            {isOpen
                ? spreadsheet.ranges.map(r => (
                      <Range range={r} spreadsheetId={spreadsheet.id} key={r.range} onDeleted={onDeleted} />
                  ))
                : null}
        </div>
    );

    function deleteButton() {
        if (dsDeleteSheet.state.state === "pending") {
            return <button disabled>Usuwanie...</button>;
        }

        if (dsDeleteSheet.state.state === "error") {
            return <div>Błąd usuwania - załaduj stronę ponownie</div>;
        }

        if (confirmDelete) {
            return (
                <>
                    <button onClick={() => dsDeleteSheet.load({ data: { spreadsheetId: spreadsheet.id } })}>
                        Potwierdzam, usuń
                    </button>
                    <button onClick={() => setConfirmDelete(false)}>Anuluj</button>
                </>
            );
        }

        return <button onClick={() => setConfirmDelete(true)}>Usuń</button>;
    }
};

const Range: React.FC<{ range: CacheStateRange; spreadsheetId: string; onDeleted: () => void }> = ({
    range,
    spreadsheetId,
    onDeleted,
}) => {
    const dsRefreshRange = useDatasource(Endpoint.Cache.RefreshRange);
    const dsDeleteRange = useDatasource(Endpoint.Cache.DeleteRange);

    useEffect(() => {
        if (dsDeleteRange.state.state === "completed") {
            onDeleted();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dsDeleteRange.state.state]);

    if (dsDeleteRange.state.state === "completed") return null;

    const timestamp =
        dsRefreshRange.state.state === "pending"
            ? "-"
            : dsRefreshRange.state.state === "completed"
            ? dsRefreshRange.state.response.state.spreadsheets
                  .find(s => s.id === spreadsheetId)
                  ?.ranges.find(r => r.range === range.range)?.timestamp ?? "-"
            : range.timestamp;

    return (
        <div className="range">
            <div className="range__name">{range.range}</div>
            <div className="range__timestamp">{formatDateTime(timestamp)}</div>
            <div className="range__refresh">
                {refreshButton()}
                {deleteButton()}
            </div>
        </div>
    );

    function refresh() {
        dsRefreshRange.load({
            data: {
                spreadsheetId,
                range: range.range,
            },
        });
    }

    function deleteRange() {
        dsDeleteRange.load({
            data: {
                spreadsheetId,
                range: range.range,
            },
        });
    }

    function refreshButton() {
        switch (dsRefreshRange.state.state) {
            case "idle":
            case "completed":
                return <button onClick={() => refresh()}>Refresh</button>;

            case "pending":
                return <div>Refreshing...</div>;

            case "error":
                return <div>Error!</div>;
        }
    }

    function deleteButton() {
        switch (dsDeleteRange.state.state) {
            case "idle":
            case "completed":
                return <button onClick={() => deleteRange()}>Delete</button>;

            case "pending":
                return <div>Deleting...</div>;

            case "error":
                return <div>Error!</div>;
        }
    }
};
