import { useStateLocalStorage } from "./useStateLocalStorage";

export function useAuthToken() {
    const [authToken, setAuthToken, authTokenRef] = useStateLocalStorage<string | null>(null, "AuthToken");
    return {
        authToken,
        authTokenRef,
        setAuthToken,
    };
}
