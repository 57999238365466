import { useDatasource as useDs } from "@nexcodepl/endpoint-client";
import { EndpointDefinition } from "@nexcodepl/endpoint-types";
import { useContextApp } from "../context/context";

export function useDatasource<TEndpoint extends EndpointDefinition<any, any, any, boolean>>(
    endpointDefinition: TEndpoint
) {
    const {
        authToken: { authTokenRef },
    } = useContextApp();
    return useDs(
        {
            ...endpointDefinition,
            url: "https://crm-cache-api.api-proxy.pl/api" + endpointDefinition.url,
            // url: "http://localhost:4000/api" + endpointDefinition.url,
        },
        async () => {
            return { Authorization: `Bearer ${authTokenRef.current}` };
        }
    );
}

export function useDatasourceWebGenerate<TEndpoint extends EndpointDefinition<any, any, any, boolean>>(
    endpointDefinition: TEndpoint
) {
    const {
        authToken: { authTokenRef },
    } = useContextApp();
    return useDs(
        {
            ...endpointDefinition,
            url: "https://3destate.app/api" + endpointDefinition.url,
            // url: "http://localhost:4000/api" + endpointDefinition.url,
        },
        async () => {
            return { Authorization: `Bearer ${authTokenRef.current}` };
        }
    );
}
